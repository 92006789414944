import React from 'react'
import { Input } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';

const TaskInput = React.memo(({ handleChange, handleKeyPress, createTask, taskInput }) => {
    // console.log('TaskInput component render')

    return (
        <div className='text-field'
            style={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "5px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: 'space-between',
                width: "100%",
                height: "50px",
                margin: "auto",
            }}>

            <Input
                type='text field'
                placeholder='New task...'
                value={taskInput}
                onChange={(event) => handleChange(event)}
                onKeyPress={(event) => handleKeyPress(event, createTask)}
                className="text-field"
                disableUnderline={true}
                inputProps={{maxLength: 80}}
            />

            <AddBoxIcon
                style={{
                    color: "white",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer"
                }}
                onClick={createTask}
            />
        </div>)
}
)
export default TaskInput