const setLocalStorage = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
}

const getLocalStorage = (key) => {
    const json = window.localStorage.getItem(key)
    return JSON.parse(json)
}

export default {
    setLocalStorage,
    getLocalStorage
}