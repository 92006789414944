import React from 'react';

import TasksLists from '../components/TasksLists'
import RestIdeas from '../components/RestIdeas'
import StartPauseButton from '../components/StartPauseButton'
import TaskInput from '../components/TaskInput'
import Timer from '../components/Timer'
import TimerTypeSelection from '../components/TimerTypeSelection'

const TimerBody = React.memo((props) => {
    const {
        currentFocus,
        cycles,
        draggedLink,
        restIdeas,
        tasks,
        taskInput,
        timerIsRunning,
        timeRemaining,
        timerType,
        composeDisplayTime,
        createTask,
        handleChange,
        handleKeyPress,
        increaseTimeByMinute,
        onCurrentFocusClick,
        onDragEnd,
        onTimerTypeClick,
        reduceTimeByMinute,
        removeTask,
        setReactGAClickEvent,
        timer,
        toggleTaskNew
    } = props

    return (
        <div className="timer-body">
            <div className="timer-container">
                <TimerTypeSelection
                    timerType={timerType}
                    onTimerTypeClick={onTimerTypeClick}
                />
                <h3>Pomo Count: {cycles}</h3>
                <Timer
                    composeDisplayTime={composeDisplayTime}
                    timerType={timerType}
                    timeRemaining={timeRemaining}
                    increaseTimeByMinute={increaseTimeByMinute}
                    reduceTimeByMinute={reduceTimeByMinute}
                />
                <StartPauseButton
                    timerType={timerType}
                    timer={timer}
                    timerIsRunning={timerIsRunning}
                />
            </div>
            <div className="task-input">
                <TaskInput
                    handleChange={handleChange}
                    handleKeyPress={handleKeyPress}
                    createTask={createTask}
                    taskInput={taskInput}
                />
            </div>
            
                {timerType === "short rest" || timerType === "long rest"
                    ? <RestIdeas
                        restIdeas={restIdeas}
                        timerType={timerType}
                    />
                    : null}
                {
                    timerType === "focus"
                    ? <TasksLists 
                        tasks = {tasks}
                        removeTask = {removeTask}
                        toggleTaskNew = {toggleTaskNew}
                        draggedLink = {draggedLink}
                        onDragEnd ={onDragEnd}
                        setReactGAClickEvent={setReactGAClickEvent}
                        timerType={timerType}
                    />
                    : null
            }
        </div>
    )
})

export default TimerBody