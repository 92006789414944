import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd'

import ItemsList from '../components/ItemsList'

const TasksLists = React.memo(({ tasks, timerType, onDragEnd, removeTask, toggleTaskNew, draggedLink, setReactGAClickEvent}) => {

    const activeTasks = []
    const completedTasks = [] 
    const savedTasks = []
    if(tasks && tasks.length){ 
        tasks.forEach((task, index) => {
            if(!task.completed && !task.saved){
                console.log('active task')
                activeTasks.push({...task, originalIndex: index})
            }
            else if(task.completed){
                console.log('task.completed')
                completedTasks.push({...task, originalIndex: index})
            } else if (task.saved){
                console.log('task.saved')
                savedTasks.push({...task, originalIndex: index})
            }
        }) 
    }

    return (
        <div className={timerType}>

            {/* {timerType === "focus"
                ? (
                    <div>
                        {!!currentFocus.description && currentFocus.completed !== true
                            ?
                            <div>
                                <div className="task-titles">
                                    <div style={{ width: "24px" }}></div>
                                    <h2 className="tasks-title">Current Focus</h2>
                                    <div style={{ width: "24px" }}></div>
                                </div>
                                <hr className="hr" />
                                <div>{currentFocus.description}</div>
                            </div>
                            : null
                        } */}
                    <DragDropContext
                        onDragEnd={onDragEnd}
                    >
                        <ItemsList
                            itemsList={activeTasks}
                            removeTask={removeTask}
                            itemsListType='tasks'
                            displayInterval={100}
                            toggleTaskNew={toggleTaskNew}
                            // onCurrentFocusClick={onCurrentFocusClick}
                            setReactGAClickEvent={setReactGAClickEvent}
                            draggedLink={draggedLink}
                        />
                    </DragDropContext>
                    {/* /div>
                )
                : null
             */}
            {savedTasks && savedTasks.length > 0
                ?
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <ItemsList
                        itemsList={savedTasks}
                        removeTask={removeTask}
                        itemsListType='savedTasks'
                        displayInterval={0}
                        setReactGAClickEvent={setReactGAClickEvent}
                        toggleTaskNew={toggleTaskNew}
                        draggedLink={draggedLink}
                    />
                </DragDropContext>
                : null
            }
            {completedTasks && completedTasks.length > 0
                ?
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <ItemsList
                        itemsList={completedTasks}
                        removeTask={removeTask}
                        itemsListType='completedTasks'
                        displayInterval={0}
                        toggleTaskNew={toggleTaskNew}
                        setReactGAClickEvent={setReactGAClickEvent}
                        draggedLink={draggedLink}
                    />
                </DragDropContext>

                : null
            }
        </div>
    )
})

export default TasksLists