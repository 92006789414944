import React from 'react'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Timer = ({composeDisplayTime, timerType, timeRemaining, increaseTimeByMinute, reduceTimeByMinute}) =>{
  // console.log('Timer component render')

    return(
        <div display="flex">
          <ExpandMoreIcon 
            onClick = {reduceTimeByMinute}
            style={{cursor: "pointer", width: "24px", color: "white"}}
          />
          <ExpandLessIcon 
            onClick = {increaseTimeByMinute}
            style={{cursor: "pointer", width: "24px", color: "white"}}
          />
          <div className="timer-string">{composeDisplayTime(timeRemaining, timerType)}</div>
        </div>
    )
  }

export default Timer