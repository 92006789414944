import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import localUtils from './localUtils'

const config = {
    apiKey: "AIzaSyA9Uk49pYN1vI_zQOCN8fw2qRBpaAmK9yM",
    authDomain: "pomopal-247f5.firebaseapp.com",
    databaseURL: "https://pomopal-247f5.firebaseio.com",
    projectId: "pomopal-247f5",
    storageBucket: "pomopal-247f5.appspot.com",
    messagingSenderId: "1077909700465",
    appId: "1:1077909700465:web:db23a18d4f15a02f9a40d0",
    measurementId: "G-3KL0Q1JPN3"
};
// Initialize Firebase

export const createUserProfileDocument = async (userAuth) => {
    if (!userAuth) return;

    const userRef = firestore.doc(`users/${userAuth.uid}`);

    const snapShot = await userRef.get();

    // console.log('snapShot', snapShot)
    if(!snapShot.exists){
        const { displayName, email, photoURL } = userAuth;
        const createdAt = new Date()
        const tasks = localUtils.getLocalStorage('tasks')
        // console.log('tasks to set to firebase', tasks)

        try {
            await userRef.set({
                displayName,
                email,
                photoURL,
                createdAt,
                tasks: [...(tasks||[])]
            })
        } catch(error){
            console.error('error creating user', error)
        }
        
    }

    return userRef;
}

export const setUserData = async (dataName, data) => {
    // console.log('setUserDatay', dataName, data)
    const userId = auth.currentUser.uid
    // console.log('uid', userId)
    const userRef = firestore.doc(`users/${userId}`)
    // console.log('userRef', userRef)
    const snapShot = await userRef.get()
    // console.log('snapShot', snapShot, snapShot.data())
    const snapShotData = snapShot.data()
    if(!!snapShot){
        try{
            await userRef.set({
                ...snapShotData,
                [dataName]: data
            })
        } catch(error){
            console.error(`could not set users ${dataName}`, error)
        }
    }
}

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;