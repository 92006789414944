import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

import ItemsListTitle from './ItemsListTitle'
import Task from './Task'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

class ItemsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isExpanded: true,
        }
    }

    onExpandClick = () => {
        const { setReactGAClickEvent } = this.props;
        setReactGAClickEvent(`Expand/Contract List`)
        this.setState(prevState=>({
            ...prevState,
            isExpanded: !prevState.isExpanded
        }))
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.draggedLink.movedDate!==this.props.draggedLink.movedDate 
            || nextProps.itemsList.length!==this.props.itemsList.length
            || nextState.isExpanded !== this.state.isExpanded
            ){
            return true
        } else {
            return false
        }
    }

    render() {
        const { isExpanded } = this.state
        const {itemsList, onCurrentFocusClick, removeTask, itemsListType, toggleTaskNew } = this.props
        const ExpandDirection = isExpanded ? ExpandLessIcon : ExpandMoreIcon
        const displayItemsList = itemsList
        const listName = itemsListType==="completedTasks" ? "Completed" : itemsListType==="savedTasks" ? "Saved For Later" : "Tasks To Do"
        // console.log('ItemsList component render')
        return (
            <Droppable droppableId = {itemsListType}>
             {provided => (   
            <div className="task-container" style={{marginTop: "25px"}} ref = {provided.innerRef} {...provided.droppableProps}>
                <ItemsListTitle 
                    ExpandDirection = {ExpandDirection}
                    listName = {listName}
                    onExpandClick = {this.onExpandClick}
                    itemsListLength = {itemsList.length}
                />
                
                <div className={`task-ideas-${isExpanded}`}> 
                    {
                        displayItemsList.map((task, index) => {
                            // console.log('happening?')
                            return (
                                <Task 
                                    index = {index}
                                    task = {task}
                                    toggleTaskNew = {toggleTaskNew}
                                    removeTask = {removeTask} 
                                    key={index}
                                />
                            )
                        })
                    }
                </div>
                {provided.placeholder}
            </div>)}
            </Droppable>
        )
    }
}

export default ItemsList