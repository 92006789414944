import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

//styling
import './App.css';

//containers
import TimerBody from './containers/TimerBody'
import UserAccount from './containers/UserAccount'

//components
import Nav from './components/Nav'
import About from './components/About'
import Footer from './components/Footer'
import PomoHelmet from './components/PomoHelmet'

//utils
import localUtils from './utils/localUtils'
import { auth, createUserProfileDocument, setUserData } from './utils/firebaseUtils'

//assets
import restIdeas from './restIdeas'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeRemaining: 1500,
      timerIsRunning: false,
      timerType: "focus",
      restIdeas: [],
      cycles: 0,
      isAboutClicked: false,
      taskInput: '',
      tasks: [],
      currentUser: null,
      currentFocus: {},
      draggedLink: {}
    }
    ReactGA.initialize('UA-145479778-2');
    ReactGA.pageview(window.location.pathname);
    this.myRef = React.createRef()
    this.taskListTypes = ['tasks', 'completedTasks', 'savedTasks', 'deletedTasks']
    this.timers = [
      {
        name: "focus",
        duration: 1500
      },
      {
        name: "short rest",
        duration: 300
      },
      {
        name: "long rest",
        duration: 900
      }
    ]
  }

  unsubscribeFromAuth = null;

  setReactGAClickEvent = (itemClicked) => {
    ReactGA.event({
      category: 'User',
      action: `Click ${itemClicked}`
    });
  }

  onSignOut = () => {
    auth.signOut()
    localStorage.clear()
    this.setReactGAClickEvent('sign out')
    this.setState(prevState => ({
      ...prevState,
      [this.taskListTypes[0]]: []
    }))
  }


  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let sourceIdx = parseInt(result.source.index)
    // console.log('sourceIdx', sourceIdx)
    let destIdx = parseInt(result.destination.index)
    // console.log('destIdx', destIdx)
    let draggedLink = {...this.state.tasks[sourceIdx], movedDate: new Date()}
    // console.log('draggedLink', draggedLink)
    let newList = this.state.tasks.slice();
    newList.splice(sourceIdx, 1);
    newList.splice(destIdx, 0, draggedLink)
     this.setState((prevState) => ({
      ...prevState,
      [this.taskListTypes[0]]: newList,
      draggedLink
    }), () => {
      localUtils.setLocalStorage(this.taskListTypes[0], this.state[this.taskListTypes[0]])
      return this.state.currentUser ? setUserData(this.taskListTypes[0], this.state[this.taskListTypes[0]]) : null
    })
  }

  startTimer = 0

  componentDidMount() {
    const methodName = 'componentDidMount'
    //console.log(methodName)
    const toDoTaskName = this.taskListTypes[0]

    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = createUserProfileDocument(userAuth);

        (await userRef).onSnapshot(async snapShot => {
          const localTasks = localUtils.getLocalStorage(toDoTaskName);
          const snapShotData = snapShot.data()
          const snapShotDataTasks = snapShotData.tasks
          let uniqueTasks = []
          if (localTasks && localTasks.length && snapShotDataTasks && snapShotDataTasks.length) {
            let combinedTasks = [...snapShotDataTasks, ...localTasks]
            let uniqueTaskKeysSet = new Set(combinedTasks.map(task => task.key))
            let uniqueTasksKeysArr = [...uniqueTaskKeysSet]
            uniqueTasks = uniqueTasksKeysArr.map(taskKey => combinedTasks.find(task => task.key === taskKey))
            await setUserData(this.taskListTypes[0], uniqueTasks)
            localUtils.setLocalStorage(toDoTaskName, uniqueTasks)
          } else if (snapShotDataTasks && snapShotDataTasks.length) {
            uniqueTasks = snapShotDataTasks
            localUtils.setLocalStorage(toDoTaskName, uniqueTasks)
          } else {
            uniqueTasks = localTasks
            await setUserData(this.taskListTypes[0], uniqueTasks)
            localUtils.setLocalStorage(toDoTaskName, uniqueTasks)
          }
          this.setState(prevState => ({
            ...prevState,
            currentUser: {
              ...snapShot.data(),
              tasks: (uniqueTasks && uniqueTasks.length) ? uniqueTasks : []
            },
            tasks: uniqueTasks
          }));
        })
      } else {
        this.setState({
          currentUser: userAuth
        })
      }
    })

    const localTasks = localUtils.getLocalStorage(toDoTaskName)
    // console.log('localTasks1', localTasks, 'state', this.state)

    if (!localTasks) {
      localUtils.setLocalStorage(toDoTaskName, this.state[toDoTaskName])
    }

    // console.log('localTasks', localTasks, 'state', this.state)
    this.setState((prevState) => ({
      ...prevState,
      [toDoTaskName]: localTasks
    }))
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  onCurrentFocusClick = (currentFocus) => {
    this.setState((prevState) => ({
      ...prevState,
      currentFocus
    }))
  }

  getRestIdeas = () => {
    const shortRestIdeasList = []
    for (let i = 0; i < 3; i++) {
      const restIdea = restIdeas[Math.floor(Math.random() * restIdeas.length)]
      shortRestIdeasList.push(restIdea)
    }
    return shortRestIdeasList
  }

  playAudioClip = async (audioClip) => {
    // console.log('playingAudioClip', audioClip, typeof audioClip)
    let audio = new Audio(audioClip);
    const audioPromise = await audio.play()
    if (audioPromise !== undefined) {
      try {
        // console.log('autoplay started')
      } catch (err) {
        console.error('autoplay failed', err)
      }
    }
  }

  incrementOrDecrementState = (key, amount) => {
    this.setState((prevState) => ({
      [key]: prevState[key] + amount
    }))
  }

  switchFromFocus = () => {
    this.incrementOrDecrementState('cycles', 1)
    if (this.state.cycles % 4 === 0) {
      this.onTimerTypeClick(this.timers[2].name, this.timers[2].duration)
    } else {
      this.onTimerTypeClick(this.timers[1].name, this.timers[1].duration)
    }
  }

  handleCountdownEnd = () => {
    const { timerType } = this.state;
    this.playAudioClip('https://s3.amazonaws.com/pomopal.app/Alarm.mp3')
    this.pauseTime()
    if (timerType === this.timers[0].name) {
      this.switchFromFocus()
    }
    else if (timerType === this.timers[1].name || timerType === this.timers[2].name) {
      this.onTimerTypeClick(this.timers[0].name, this.timers[0].duration)
    }
  }

  updateTaskInput = (text) => {
    this.setState({
      taskInput: text
    })
  }

  createTask = () => {
    if (!this.state.taskInput.length) {
      return;
    }
    this.setReactGAClickEvent('Create Task')
    this.setState((prevState) => ({
      ...prevState,
      [this.taskListTypes[0]]: [
        ...(prevState[this.taskListTypes[0]] || []),
        {
          description: prevState.taskInput,
          completed: false,
          saved: false,
          key: `${prevState.taskInput + Math.random()}`,
          createdDatetime: new Date(),
        }
      ],
      taskInput: ''
    }), () => {
      localUtils.setLocalStorage(this.taskListTypes[0], this.state[this.taskListTypes[0]])
      return this.state.currentUser ? setUserData(this.taskListTypes[0], this.state[this.taskListTypes[0]]) : null
    }
    )
  }

  getIndexFromKey = (key) => {
    const { tasks } = this.state
    let taskIndex;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].key === key) {
        taskIndex = i
        break;
      }
    }
    return taskIndex
  }

  removeTask = (key, actionType = 'delete') => {
    let taskIndex = this.getIndexFromKey(key)
    // console.log('remove at index', taskIndex)
    this.setReactGAClickEvent(actionType)
    this.setState((prevState) => ({
      ...prevState,
      tasks: [...prevState.tasks.slice(0, taskIndex), ...prevState.tasks.slice(taskIndex + 1)],
      deletedTasks: [
        ...(prevState[this.taskListTypes[3]] || []),
        {
          ...prevState.tasks[taskIndex],
          deletedDatetime: new Date()
        }
      ]
    }), async () => {
      localUtils.setLocalStorage(this.taskListTypes[0], this.state.tasks)
      if (this.state.currentUser) {
        try {
          await setUserData(this.taskListTypes[0], this.state[this.taskListTypes[0]])
        } catch (err) {
          console.error('error updating user tasks in firebase', err)
        }
        try {
          await setUserData(this.taskListTypes[3], this.state[this.taskListTypes[3]])
        } catch (err) {
          console.error('error updating user deleted tasks in firebase', err)
        }
        return;
      }
    })
  }

  toggleTaskNew = (task, toggleType, nonToggleType) => {
    let taskIndex = this.getIndexFromKey(task.key)

    let toggledTask = task
    // console.log('toggledTaskPre', toggledTask)

    toggledTask = {
      ...toggledTask,
      [toggleType]: !toggledTask[toggleType],
      [toggleType + 'Datetime']: new Date()
    }
    // console.log('toggledTaskPostCompletionUpdate', toggledTask)

    if (toggledTask[toggleType]) {
      toggledTask = { ...toggledTask, [nonToggleType]: false }
    }
    // console.log('toggledTaskPostSavedUpdate', toggledTask)

    this.setState(prevState => ({
      ...prevState,
      tasks: [...prevState.tasks.slice(0, taskIndex), toggledTask, ...prevState.tasks.slice(taskIndex + 1)]
    }), () => {
      localUtils.setLocalStorage(this.taskListTypes[0], this.state.tasks)
      return this.state.currentUser ? setUserData(this.taskListTypes[0], this.state[this.taskListTypes[0]]) : null
    })
  }

  reduceTime = () => {
    const { timeRemaining } = this.state;
    if (!timeRemaining) {
      this.handleCountdownEnd()
    } else {
      this.incrementOrDecrementState('timeRemaining', -1)
    }
  }

  reduceTimeByMinute = () => {
    console.log("reduceTimeByMinute")
    const { timeRemaining } = this.state;
    if (timeRemaining-60<=0) {
      this.setState({
        timeRemaining: 0
      })
    } else {
      this.incrementOrDecrementState('timeRemaining', -60)
    }
  }

  increaseTimeByMinute = () => {
    console.log("increaseTimeByMinute")
    this.incrementOrDecrementState('timeRemaining', 60)
  }

  pauseTime = () => {
    clearInterval(this.startTimer)
    this.setState({
      timerIsRunning: false
    })
  }

  startTime = () => {
    this.setState({ timerIsRunning: true })
    this.startTimer = setInterval(this.reduceTime, 1000)
  }

  timer = () => {
    this.setReactGAClickEvent('Start Timer')
    this.playAudioClip('https://s3.amazonaws.com/pomopal.app/ButtonClick.mp3')
    this.handleStartAndPause()
  }

  handleStartAndPause = () => {
    if (this.state.timerIsRunning) {
      this.pauseTime()
    } else {
      this.startTime()
    }
  }

  setTimerTypeState = (timerType, timeRemaining) => {
    const restIdeas = this.getRestIdeas()
    this.setState(() => ({
      timerType,
      timeRemaining,
      restIdeas
    }))
  }

  onTimerTypeClick = (timerType, timeRemaining) => {
    // console.log("timerType", timerType, timeRemaining)
    this.setReactGAClickEvent(timerType)
    this.pauseTime()
    this.setTimerTypeState(timerType, timeRemaining)
  }

  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop)

  onAboutClick = () => {
    this.setReactGAClickEvent('about')
    if (!this.state.isAboutClicked) {
      this.scrollToMyRef()
    }
    this.setState(prevState => ({
      ...prevState,
      isAboutClicked: !prevState.isAboutClicked
    }))
  }

  onContactClick = () => {
    this.setReactGAClickEvent('contact')
  }

  handleChange = (event) => {
    this.updateTaskInput(event.target.value)
  }

  handleKeyPress = (event, cb) => {
    if (event.key === 'Enter') {
      cb()
    }
  }

  composeDisplayMinutes = (timeRemaining) => {
    let displayMinutes = Math.floor(timeRemaining / 60);
    return displayMinutes
  }

  composeDisplaySeconds = (timeRemaining) => {
    let sec = timeRemaining % 60
    let displaySeconds = sec === 0 ? `${sec}0` : (sec < 10 ? `0${sec}` : sec)
    return displaySeconds
  }

  composeDisplayTime = (timeRemaining) => {
    const displayMinutes = this.composeDisplayMinutes(timeRemaining);
    const displaySeconds = this.composeDisplaySeconds(timeRemaining)
    return `${displayMinutes}:${displaySeconds}`
  }

  composeTimerTitle = (timeRemaining, timerType) => {
    const displayTime = this.composeDisplayTime(timeRemaining)
    const directionType = timerType === this.timers[0].name ? "Focus" : "Rest"
    const direction = `Time to ${directionType}!`
    return `${displayTime} - ${direction}`
  }

  selectTimerTitle = (timerIsRunning, timeRemaining, timerType) => {
    const defaultTitle = "Pomodoro Timer"
    if (timerIsRunning) {
      return this.composeTimerTitle(timeRemaining, timerType)
    } else {
      return defaultTitle
    }
  }

  render() {
    const methodName = 'App render'
    // console.log(methodName)
    const { currentUser, currentFocus, draggedLink, tasks, taskInput, timerType, restIdeas, timerIsRunning, isAboutClicked, timeRemaining, cycles } = this.state;
    const title = this.selectTimerTitle(timerIsRunning, timeRemaining, timerType)
    const timerTypeClass = timerType.split(" ").join("")
    const aboutClass = isAboutClicked ? "description-visible" : "description-hidden-" + timerTypeClass
    // console.log('createdDate', new Date())
    return (
      <div className={timerType === this.timers[0].name ? `App-${this.timers[0].name}` : timerType === this.timers[1].name ? "App-short-rest" : "App-long-rest"}>
        <PomoHelmet title={title} />
        <div className="main-body">
          <Nav
            onAboutClick={this.onAboutClick}
            currentUser={currentUser}
            onSignOut={this.onSignOut}
            setReactGAClickEvent= {this.setReactGAClickEvent}
          />
          <Switch>
            <Route
              path='/'
              exact={true}
              render={() => <TimerBody
                currentFocus={currentFocus}
                cycles={cycles}
                draggedLink={draggedLink}
                restIdeas={restIdeas}
                tasks={tasks}
                taskInput={taskInput}
                timerIsRunning={timerIsRunning}
                timeRemaining={timeRemaining}
                timerType={timerType}
                composeDisplayTime={this.composeDisplayTime}
                createTask={this.createTask}
                handleChange={this.handleChange}
                handleKeyPress={this.handleKeyPress}
                increaseTimeByMinute={this.increaseTimeByMinute}
                onCurrentFocusClick={this.onCurrentFocusClick}
                onDragEnd={this.onDragEnd}
                onTimerTypeClick={this.onTimerTypeClick}
                reduceTimeByMinute={this.reduceTimeByMinute}
                removeTask={this.removeTask}
                setReactGAClickEvent= {this.setReactGAClickEvent}
                timer={this.timer}
                toggleTaskNew={this.toggleTaskNew}
              />
              }
            />
            <Route
              path='/myAccount'
              render={() => <UserAccount />}
            />
          </Switch>
        </div>
        <div ref={this.myRef} >
          <About aboutClass={aboutClass} />
        </div>

        <Footer
          onContactClick={this.onContactClick}
        />
      </div>
    );
  }
}

export default withRouter(App);