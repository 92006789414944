import React from 'react'

const TimerTypeSelection = React.memo(({timerType, onTimerTypeClick}) =>{
  // console.log('TimerTypeSelection component render')

  return(
    <div className="timer-settings">
    <button 
      className = {timerType==="focus" ? "selected-button-focus" : "not-selected-button-focus"}
      onClick={()=>onTimerTypeClick('focus', 1500)}
    >
      Focus
    </button>
    <button 
      className = {timerType==="short rest" ? "selected-button-short-rest" : "not-selected-button-short-rest"}
      onClick={()=>onTimerTypeClick('short rest', 300)}
    >
      Short Rest
    </button>
    <button 
      className = {timerType==="long rest" ? "selected-button-long-rest" : "not-selected-button-long-rest"}
      onClick={()=>onTimerTypeClick('long rest', 900)}
    >
      Long Rest
    </button>
  </div>
  )
})

export default TimerTypeSelection