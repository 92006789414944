import React from 'react';
import { Link } from "react-router-dom"

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

export default function UserMenu({ userImageURL, setReactGAClickEvent, signOut }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setReactGAClickEvent('profile icon')
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setReactGAClickEvent('close profile icon')
        setAnchorEl(null);
    };
    console.log('UserMenu component render')

    return (
        <div>
            <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                <img
                    className="profile-image"
                    src={userImageURL}
                />
            </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                <MenuItem onClick={handleClose}>
                    <Link 
                        to="/myAccount"
                        style={{textDecoration: 'none', color: 'black'}}
                        onClick={()=>setReactGAClickEvent('my account')}
                    >
                        My account
                    </Link>
                </MenuItem>
                <MenuItem onClick={signOut}>Logout</MenuItem>
            </Menu>
        </div>
    );
}