import React from 'react';

const ItemsListTitle = React.memo(({ listName, itemsListLength, onExpandClick, ExpandDirection }) => {
    // console.log('ItemsListTitle component render')

    return (
        <div>
        <div className="task-titles">
                    <div style={{width: "24px"}}></div>
                    <h2 className="tasks-title">{listName}</h2>
                    <ExpandDirection 
                        onClick={onExpandClick}
                        style={{cursor: "pointer", width: "24px"}}
                    />
                </div>
                <div style={{fontSize: "12px"}}>{itemsListLength + (itemsListLength===1 ? " task":" tasks")}</div>
                <hr className="hr" />
                </div>
        )
})

export default ItemsListTitle