import React from 'react';
import { Helmet } from 'react-helmet';

const PomoHelmet = ({ title })=>{
    // console.log('PomoHelmet component render')

    return(
        <Helmet defer={false} title={title}>
            <title>{title}</title>
            <meta
                name="description"
                content="A Pomodoro productivity app. Designed to help you focus. Simple, clean, mobile and desktop optimized. Includes little ideas for how to get the most from your rest time, too. Stay focused."
            ></meta>
        </Helmet>
    )
}

export default PomoHelmet


