import React from 'react';

// import ItemsList from '../components/ItemsList'
// import RestIdeas from '../components/RestIdeas'
// import StartPauseButton from '../components/StartPauseButton'
// import TaskInput from '../components/TaskInput'
// import Timer from '../components/Timer'
// import TimerTypeSelection from '../components/TimerTypeSelection'

const UserAccount = (props) => {
    // console.log('UserAccount component render')

    return (
        <div className="timer-body">
            <div className="timer-container">
                {/* <TimerTypeSelection
                    timerType={timerType}
                    onTimerTypeClick={onTimerTypeClick}
                /> */}
                <h3>Coming soon...</h3>
                {/*<Timer
                    composeDisplayTime={composeDisplayTime}
                    timerType={timerType}
                    timeRemaining={timeRemaining}
                />
                
                <StartPauseButton
                    timerType={timerType}
                    timer={timer}
                    timerIsRunning={timerIsRunning}
                /> */}
            </div>
            <div className="task-input">
                {/* <TaskInput
                    handleChange={handleChange}
                    handleKeyPress={handleKeyPress}
                    createTask={createTask}
                    taskInput={taskInput}
                /> */}
            </div>
            <div>
{/* 
                {timerType === "short rest" || timerType === "long rest"
                    ? <RestIdeas
                        restIdeas={restIdeas}
                        timerType={timerType}
                    />
                    : null}
                {
                    timerType === "focus"
                        ? <ItemsList
                            itemsList={activeTasks}
                            removeTask={removeTask}
                            itemsListType='tasks'
                            displayInterval={100}
                            toggleTaskNew={toggleTaskNew}
                        />
                        : null
                }
                {completedTasks && completedTasks.length > 0 && timerType === "focus"
                    ? <ItemsList
                        itemsList={completedTasks}
                        removeTask={removeTask}
                        itemsListType='completedTasks'
                        displayInterval={0}
                        toggleTaskNew={toggleTaskNew}

                    />
                    : null
                } */}
                {/* {savedTasks && savedTasks.length > 0 && timerType === "focus"
                    ? <ItemsList
                        itemsList={savedTasks}
                        removeTask={removeTask}
                        itemsListType='savedTasks'
                        displayInterval={0}
                        toggleTaskNew={toggleTaskNew}

                    />
                    : null
                } */}
            </div>
        </div>
    )
}

export default UserAccount