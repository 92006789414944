const restIdeas = [
    "Do 10 pushups",
    "Do 10 hammer curls",
    "Do 10 bicep curls",
    "Do 10 squats",
    "Do 10 calf lifts",
    "Read a page in a book",
    "Pet your cat and / or dog",
    "Do a lap around the office",
    "Refill your glass of water",
    "Refill your coffee",
    "Play a level of Super Mario",
    "Play a level of Super Meat Boy",
    "Play a round of Tony Hawk's Pro Skater",
    "Race 3 laps in Crash Team Racing",
    "Have a quick meditation session",
    "Do a few minutes of mindful breathing",
    "Listen to a great song",
    "Eat a snack",
    "Wish a family member a good day via text",
    "Wish a friend a good day via text",
    "Do a few minutes of light stretching",
    "Practice another language with flash cards",
    "Look for a great recipe to cook",
    "Make a grocery list",
    "Make sure you're registered to vote",
    "Watch your favorite standup bit",
    "Water your crops in Stardew Valley",
    "Watch a new movie trailer",
    "Touch your toes",
    "Do 60 situps",
    "Write a few sentences about your day",
    "Think of things you're grateful for today",
    "Think of the people you love in your life",
    "Think of a nice thing you can do for a loved one",
    "Tell someone you appreciate them",
    "Write in your journal",
    "Wherever you are, be there, enjoy it",
    "Tidy up your desk",
    "Reaffirm your goals for the day",
    "Say something nice to someone",
    "Look at a cool new product on Product Hunt",
    "Play Half-Minute Hero",
    "Look at photos of cats",
    "Look at photos of national parks",
    "Look at photos of Venice",
    "Look at photos of Hawaii",
    "Think of where you want to vacation next",
    "Give to a worthy cause",
    "Remind your friends and family to register to vote",
    "Play an instrument"
]

export default restIdeas