import React from 'react';
import { Draggable } from 'react-beautiful-dnd'

import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WatchLaterIcon from '@material-ui/icons/WatchLater';


class Task extends React.Component {
    constructor(props){
        super(props)
    }

    shouldComponentUpdate(nextProps){
        if(this.props.task.key!==nextProps.task.key){
            return true
        } else {
            return false
        }
    }

    render(){
        const { index, task, toggleTaskNew, removeTask } = this.props;
        // console.log('rerender, index', index, task)
        return (
            <Draggable 
                draggableId = {`${task.key}`} 
                index={task.originalIndex}
                key={task.key}
            >
            {(provided)=>(
            <div
                className="task"
                ref = {provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <h4
                // onClick={()=>onCurrentFocusClick(task)}
                >{task.description}</h4>
                <div className="task-actions">
                    <CheckCircleIcon
                        index={index}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                        onClick={() => toggleTaskNew(task, 'completed', 'saved')}
                        className={`task-check-completed-${task.completed}`}
                    />
                    <WatchLaterIcon
                        index={index}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                        onClick={() => toggleTaskNew(task, 'saved', 'completed')}
                        className={`task-check-saved-${task.saved}`}

                    />
                    <DeleteIcon
                        index={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => removeTask(task.key)}
                    />
                </div>
            </div>)}
            </Draggable>
        )
    }
}

export default Task